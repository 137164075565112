import { bnHelper } from '@/helpers/bignumber-helper'
import { localdata } from '@/helpers/local-data'
import { TokenInfoModel } from '@/models/token-info-model'
import { FixedNumber } from '@ethersproject/bignumber'
import { MARKETS } from '@project-serum/serum'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { ENV as SOL_CHAINID, TokenInfo, TokenListProvider } from '@solana/spl-token-registry'
import { PublicKey } from '@solana/web3.js'
import axios from 'axios'
import BufferLayout from 'buffer-layout'
import { mapValues, round } from 'lodash-es'
import moment, { Moment } from 'moment'
import { blockchainHandler } from '.'

const provider = blockchainHandler.getSolanaConfig(SOL_CHAINID.MainnetBeta)

export const ACCOUNT_LAYOUT = BufferLayout.struct([
  BufferLayout.blob(32, 'mint'),
  BufferLayout.blob(32, 'owner'),
  BufferLayout.nu64('amount'),
  BufferLayout.blob(93),
])

export const MINT_LAYOUT = BufferLayout.struct([
  BufferLayout.blob(44),
  BufferLayout.u8('decimals'),
  BufferLayout.blob(37),
])

export function parseMintData(data) {
  const { decimals } = MINT_LAYOUT.decode(data)
  return { decimals }
}

export async function getTokenInfos(account): Promise<TokenInfoModel[]> {
  account = new PublicKey(account.toString())
  const myTokenInfors = await getTokenAccountInfo(account)
  const allTokenInfos = await getAllTokenInfors()
  // bonfida
  const results = await Promise.all(
    myTokenInfors
      .map((info) => ({
        info,
        match: allTokenInfos.find((x) => x.address === info.parsed.mint.toString()),
      }))
      .filter((x) => x.match)
      .map(async ({ info, match }) => {
        if (!match) throw new Error('should not here')

        let price = FixedNumber.from('0')
        let balance = FixedNumber.from('0')
        price = FixedNumber.from(`${await splPriceStore.getPrice(match.symbol, match)}`)
        balance = bnHelper.fromSolDecimals(info.parsed.amount, match.decimals)
        return {
          token_address: match?.address,
          name: match?.name,
          symbol: match.symbol,
          logo: match.logoURI,
          thumbnail: match.logoURI,
          decimals: match.decimals,
          balance,
          chain: 'sol',
          price,
          value: balance.mulUnsafe(price),
        } as TokenInfoModel
      })
  )
  const solBalance = bnHelper.fromSolDecimals(await provider.connection.getBalance(account))
  const solPrice = FixedNumber.from(`${await splPriceStore.getPrice('SOL')}`)
  return [
    ...results,
    {
      token_address: '0x0',
      name: 'SOL',
      decimals: 9,
      symbol: 'SOL',
      balance: solBalance,
      price: solPrice,
      value: solBalance.mulUnsafe(solPrice),
      chain: 'sol',
    },
  ]
}

let tokenInfos: TokenInfo[] | undefined = undefined

async function getAllTokenInfors() {
  if (!tokenInfos) {
    const tokens = await new TokenListProvider().resolve()
    tokenInfos = tokens.filterByChainId(SOL_CHAINID.MainnetBeta).getList()
  }
  return tokenInfos
}

export async function getTokenAccountInfo(
  account
): Promise<{ publicKey: PublicKey; parsed: { mint: PublicKey; owner: PublicKey; amount: number } }[]> {
  account = new PublicKey(account.toString())

  const { date, tokens } = localdata.getSolanaTokens(account.toString())
  if (date && moment(date).add(5, 'minute').isAfter(moment())) {
    return tokens.map(({ publicKey, parsed: { mint, owner, amount } }) => ({
      publicKey: new PublicKey(publicKey),
      parsed: {
        mint: new PublicKey(mint),
        owner: new PublicKey(owner),
        amount,
      },
    }))
  }

  const accounts = await getOwnedTokenAccounts(provider.connection, account)
  const results: { publicKey: PublicKey; parsed: { mint: PublicKey; owner: PublicKey; amount: number } }[] = accounts
    .map(({ publicKey, accountInfo }) => {
      return { publicKey, parsed: parseTokenAccountData(accountInfo.data) }
    })
    .sort((account1, account2) => account1.parsed.mint.toBase58().localeCompare(account2.parsed.mint.toBase58()))

  localdata.saveSolanaTokens(
    account.toString(),
    results.map(({ publicKey, parsed: { mint, owner, amount } }) => ({
      publicKey: publicKey.toString(),
      parsed: {
        mint: mint.toString(),
        owner: owner.toString(),
        amount,
      },
    }))
  )
  return results
}

export function parseTokenAccountData(data) {
  const { mint, owner, amount } = ACCOUNT_LAYOUT.decode(data)
  return {
    mint: new PublicKey(mint),
    owner: new PublicKey(owner),
    amount,
  }
}

export async function getOwnedTokenAccounts(connection, account) {
  const filters = getOwnedAccountsFilters(account)
  const resp = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
    filters,
  })
  return resp.map(({ pubkey, account: { data, executable, owner, lamports } }) => ({
    publicKey: new PublicKey(pubkey),
    accountInfo: {
      data,
      executable,
      owner: new PublicKey(owner),
      lamports,
    },
  }))
}

export function getOwnedAccountsFilters(publicKey) {
  return [
    {
      memcmp: {
        offset: ACCOUNT_LAYOUT.offsetOf('owner'),
        bytes: publicKey.toBase58(),
      },
    },
    {
      dataSize: ACCOUNT_LAYOUT.span,
    },
  ]
}

interface Markets {
  [coin: string]: {
    publicKey: PublicKey
    name: string
    deprecated?: boolean
  }
}

export const serumMarkets = (() => {
  const m: Markets = {}
  MARKETS.forEach((market) => {
    const coin = market.name.split('/')[0]
    if (m[coin]) {
      // Only override a market if it's not deprecated	.
      if (!m.deprecated) {
        m[coin] = {
          publicKey: market.address,
          name: market.name.split('/').join(''),
        }
      }
    } else {
      m[coin] = {
        publicKey: market.address,
        name: market.name.split('/').join(''),
      }
    }
  })
  return m
})()

let serumPairs: { tokenFrom: string; tokenTo: string; marketName: string }[] = []
let lastGetSerumPairs: Moment | null = null
try {
  const { time, infors } = JSON.parse(localStorage.getItem('spl-serum-pairs') || '{}')
  serumPairs = infors || []
  if (time) lastGetSerumPairs = moment(time)
} catch {
  //
}

const loadSerumPairsTask = (async () => {
  if (lastGetSerumPairs && lastGetSerumPairs.add(30, 'minute').isAfter(moment())) {
    return
  }
  await axios
    .get('https://serum-api.bonfida.com/pairs')
    .then((x) => x.data.data.filter((p) => p.includes('/')))
    .then((pairs: string[]) => {
      serumPairs = pairs.map((x) => {
        const sl = x.split('/')
        return {
          tokenFrom: sl[0],
          tokenTo: sl[1],
          marketName: x.replaceAll('/', ''),
        }
      })
      localStorage.setItem(
        'spl-serum-pairs',
        JSON.stringify({
          time: moment().toISOString(),
          infors: serumPairs,
        })
      )
    })
})()

let radiumAmmInfos: RaydiumLpPoolInfo[] = []
let lastGetRaidumAmm: Moment | null = null
try {
  const { time, infors } = JSON.parse(localStorage.getItem('spl-raydium-amms2') || '{}')
  radiumAmmInfos = infors || []
  if (time) {
    lastGetRaidumAmm = moment(time)
  }
} catch {
  //
}

const loadRaidumAmmTask = (async () => {
  if (lastGetRaidumAmm && lastGetRaidumAmm.add(30, 'minute').isAfter(moment())) {
    return
  }
  const { official, unOfficial } = await axios.get('https://api.raydium.io/v1/main/liquidity').then((x) => x.data)

  const ammInfos = [...Object.values(official), ...Object.values(unOfficial)]
  radiumAmmInfos = ammInfos as any[]
  localStorage.setItem(
    'spl-raydium-amms2',
    JSON.stringify({
      time: moment().toISOString(),
      infos: ammInfos,
    })
  )
})()

// Create a cached API wrapper to avoid rate limits.
const USDTAddress = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB')
const USDCAddress = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v')

class PriceStore {
  cache: {
    [id: string]: {
      time: string
      price: number
    }
  } = {}
  raydiumAmmCacheds: {
    [ammId: string]: {
      time: string
      ammInfo: RaydiumLpPoolInfo
      coinBalance: FixedNumber
      pcBalance: FixedNumber
      coinUsdPrice: FixedNumber
    }
  } = {}

  constructor() {
    this.cache = JSON.parse(localStorage.getItem('spl-token-prices') || `{}`)
    this.raydiumAmmCacheds = JSON.parse(localStorage.getItem('raydium-amm-lp-infos') || `{}`)
  }

  async getPrice(tokenSymbol, match?: TokenInfo): Promise<number> {
    try {
      await loadSerumPairsTask
    } catch {
      //
    }
    tokenSymbol = tokenSymbol.toUpperCase()
    if (tokenSymbol === 'USDC' || tokenSymbol === 'USDT') {
      return 1
    }
    let { price, time } = this.cache[tokenSymbol] || this.cache[match?.address || ''] || {}
    price = price || 0
    if (time && moment(time).add(30, 'minute').isAfter(moment())) {
      // get cache for 30m
      return price || 0
    }

    const matchUsd = serumPairs.find((x) => x.tokenFrom === tokenSymbol && ['USDT', 'USDC'].includes(x.tokenTo))
    try {
      if (matchUsd) {
        const reuslts = await axios
          .get(`https://serum-api.bonfida.com/trades/${matchUsd?.marketName}`)
          .then((x) => x.data.data)
        price = reuslts[0].price
      } else {
        const matchOther = serumPairs.find((x) => x.tokenFrom === tokenSymbol)
        let toOtherPrice = 0
        let otherPrice = 0
        if (matchOther) {
          const toOthers = await axios
            .get(`https://serum-api.bonfida.com/trades/${matchOther?.marketName}`)
            .then((x) => x.data.data)
          toOtherPrice = toOthers[0].price

          otherPrice = await this.getPrice(matchOther?.tokenTo)
        }
        price = toOtherPrice * otherPrice
      }

      if (!price && match) {
        price = await this.getRaydiumPrice(match)
      }
      time = moment().toISOString()
      this.cache[tokenSymbol] = { time, price }
      localStorage.setItem('spl-token-prices', JSON.stringify(this.cache))
    } catch (error) {
      //
    }

    return price || 0
  }

  async getRaydiumPrice(match: TokenInfo) {
    return this.getRaydiumPriceByAddress(new PublicKey(match.address))
  }

  async getRaydiumPriceByAddress(tokenAddress: PublicKey) {
    await loadRaidumAmmTask
    // eslint-disable-next-line prefer-const
    const { price: cachedPrice, time } = this.cache[tokenAddress.toString()] || {}
    let price = round(cachedPrice || 0, 16)
    if (time && moment(time).add(30, 'minute').isAfter(moment())) {
      // get cache for 30m
      return price
    }
    price = 0
    let coinBalance = FixedNumber.from('0')
    let pcBalance = FixedNumber.from('0')
    let ammInfo = radiumAmmInfos.find(
      (r) => r.coin === tokenAddress.toString() && (r.pc === USDCAddress.toString() || r.pc === USDTAddress.toString())
    )
    // first, ammInfo is USD => good to get price
    if (ammInfo) {
      // console.log('ammUsd', ammInfo)
      const info = await getRaydiumAmmDetail(ammInfo)
      coinBalance = info.coinBalance
      pcBalance = info.pcBalance
      price = round(info.price, 16)
    } else {
      const matchOther = radiumAmmInfos.find((r) => new PublicKey(r.coin).equals(tokenAddress))
      ammInfo = matchOther
      // console.log('ammOther', ammInfo)
      if (matchOther) {
        const info = await getRaydiumAmmDetail(matchOther)
        coinBalance = info.coinBalance
        pcBalance = info.pcBalance
        const toOtherPrice = info.price

        const allTokenInfos = await getAllTokenInfors()
        const tokenInfo = allTokenInfos.find((t) => t.address === matchOther.pc.toString())
        if (!tokenInfo) throw 'tokenInfo is undefined'
        // else console.log('tother pc =', tokenInfo.address)
        const otherPrice = await this.getRaydiumPrice(tokenInfo)
        price = round(toOtherPrice * otherPrice, 16)
      }
    }

    this.storeData(tokenAddress, price, ammInfo, coinBalance, pcBalance)

    return price
  }

  async getRaydiumAmmInfo(lpToken: PublicKey) {
    await loadRaidumAmmTask
    // eslint-disable-next-line prefer-const
    const cachedData = this.raydiumAmmCacheds[lpToken.toString()] || {}
    if (cachedData.time && moment(cachedData.time).add(30, 'minute').isAfter(moment())) {
      // get cache for 30m
      return cachedData
    }

    const ammInfo = radiumAmmInfos.find((r) => new PublicKey(r.lp).equals(lpToken))
    console.log(ammInfo)
    if (ammInfo) {
      const { coinBalance, pcBalance, price: toOtherPrice } = await getRaydiumAmmDetail(ammInfo)
      let price = toOtherPrice
      if (!new PublicKey(ammInfo.pc).equals(USDCAddress) && !new PublicKey(ammInfo.pc).equals(USDTAddress)) {
        const allTokenInfos = await getAllTokenInfors()
        const tokenInfo = allTokenInfos.find((t) => t.address === new PublicKey(ammInfo.pc).toString())
        if (!tokenInfo) throw 'tokenInfo is undefined'
        const otherPrice = await this.getRaydiumPrice(tokenInfo)
        price = toOtherPrice * otherPrice
      }
      this.storeData(new PublicKey(ammInfo.coin), price, ammInfo, coinBalance, pcBalance)
    }

    return this.raydiumAmmCacheds[lpToken.toString()]
  }

  private storeData(
    tokenAddress: PublicKey,
    price: number,
    ammInfo: RaydiumLpPoolInfo | undefined,
    coinBalance: FixedNumber,
    pcBalance: FixedNumber
  ) {
    const time = moment().toISOString()
    this.cache[tokenAddress.toString()] = { time, price }
    localStorage.setItem('spl-token-prices', JSON.stringify(this.cache))
    if (ammInfo) {
      this.raydiumAmmCacheds[ammInfo.lp] = {
        time,
        ammInfo,
        coinBalance,
        pcBalance,
        coinUsdPrice: FixedNumber.from(price.toString()),
      }
      localStorage.setItem(
        'raydium-amm-lp-infos',
        JSON.stringify(
          mapValues(this.raydiumAmmCacheds, ({ ammInfo, coinBalance, pcBalance, coinUsdPrice, time }) => ({
            time,
            ammInfo,
            coinBalance: coinBalance.toString(),
            pcBalance: pcBalance.toString(),
            coinUsdPrice: coinUsdPrice.toString(),
          }))
        )
      )
    }
  }
}

async function getRaydiumAmmDetail(ammInfo: RaydiumLpPoolInfo) {
  let coinBalance = FixedNumber.from('0')
  let pcBalance = FixedNumber.from('0')

  const [coinAcc, pcAcc] = await provider.connection.getMultipleAccountsInfo([
    new PublicKey(ammInfo.poolCoinTokenAccount),
    new PublicKey(ammInfo.poolPcTokenAccount),
  ])
  if (!coinAcc || !pcAcc) throw 'coinAcc or pcAcc is undefined'

  coinBalance = FixedNumber.from(ACCOUNT_LAYOUT.decode(Buffer.from(coinAcc.data)).amount.toString()).divUnsafe(
    bnHelper.getDecimals(ammInfo.coinDecimals)
  )
  pcBalance = FixedNumber.from(ACCOUNT_LAYOUT.decode(Buffer.from(pcAcc.data)).amount.toString()).divUnsafe(
    bnHelper.getDecimals(ammInfo.pcDecimals)
  )

  const price = pcBalance.divUnsafe(coinBalance).toUnsafeFloat()
  return { coinBalance, pcBalance, price }
}

export const splPriceStore = new PriceStore()

export interface RaydiumLpPoolInfo {
  coin: string
  pc: string
  lp: string
  coinDecimals: number
  pcDecimals: number
  version: number
  programId: string
  ammAuthority: string
  ammOpenOrders: string
  ammTargetOrders: string
  modelDataAccount: string
  poolCoinTokenAccount: string
  poolPcTokenAccount: string
  poolWithdrawQueue: string
  poolTempLpTokenAccount: string
  serumProgramId: string
  serumMarket: string
  serumBids: string
  serumAsks: string
  serumEventQueue: string
  serumCoinVaultAccount: string
  serumPcVaultAccount: string
  serumVaultSigner: string
}
